import React, { useState } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import brandImg from "../images/pob3d-navbar-brand.png"

function Navigation () {
  const [isActive, setisActive] = useState(false);
  const data = useStaticQuery(graphql`
    query MenuLinksQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)
  
  return (
    <nav className="navbar is-transparent" role="navigation" aria-label="main navigation">

      <div className="container">

        <div className="navbar-brand">
          <Link to={"/"} className="navbar-item">
            <img src={brandImg} alt="pob3d logo" width="112" height="27" />
          </Link>
  
          <a
            onClick={() => {
              setisActive(!isActive);
            }}
            onKeyDown={() => {
              setisActive(!isActive);
            }}
            role="button"
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="bulmaNavbarMenu"
            tabIndex="-1"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
  
        <div
          id="bulmaNavbarMenu"
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
        >
         <div className="navbar-end">
            {data.site.siteMetadata.menuLinks.map(link => (
              <div style={{display: "flex"}} key={link.name}>
                <Link to={link.link} className="navbar-item">
                  {link.name}
                </Link>
              </div>
            ))}
          </div>
        </div>

      </div>

    </nav>
  )
}

export default Navigation
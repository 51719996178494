import React from "react"
import PropTypes from "prop-types"

import Navigation from "../components/navigation"

const Header = ({ pageName }) => (
  <section className="hero hero-img is-medium">
    <div className="hero-head">
      <Navigation />
    </div>
    <div className="hero-body has-text-centered">
      <h1
        style={{
          fontStyle: `normal`,
          fontWeight: `bold`,
          color: `#eee`,
          fontSize: `11vmin`,
          letterSpacing: `0.03em`,
          lineHeight: `1`,
          textShadow: `1px 2px 4px rgba(0, 0, 0, 0.8)`,
          marginBottom: `40px`,
        }}
      >
        {pageName}
      </h1>
    </div>
  </section>
)

Header.propTypes = {
  pageName: PropTypes.string,
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  pageName: `POB 3D`,
}

export default Header
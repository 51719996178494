import React from "react"

const Footer = () => (

  <footer className="mt-5">
    © {new Date().getFullYear()}, Built with
    {` `}
    <a href="https://www.gatsbyjs.com">Gatsby</a>
  </footer>

)

export default Footer